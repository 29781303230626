export default {
  "findActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finde die richtige Aktivität"])},
  "bookActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buche aus einer Vielzahl von Aktivitäten."])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen in Ihrem Buchungsportal!"])},
  "userNameEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername oder E-Mail"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Benutzername oder Passwort"])},
  "createAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin erstellen"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
  "settingsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "selectLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache auswählen"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
  "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Passwort"])},
  "noSlotsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Slots verfügbar"])},
  "selectTimeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitfenster auswählen"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbar"])},
  "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer hinzufügen"])},
  "saveDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf speichern"])},
  "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen speichern"])},
  "publishUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer veröffentlichen"])},
  "passwordTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Passwort wird vom Benutzer für die Anmeldung verwendet. Stellen Sie sicher, dass es sicher ist. Administratoren können Passwörter festlegen und ändern, aber Benutzer können sie nach der Anmeldung ändern. Administratoren können die Passwörter der Benutzer nicht einsehen."])},
  "subRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerrolle"])},
  "remindersManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerungsverwaltung"])},
  "reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerungen"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
  "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort bestätigen"])},
  "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort aktualisieren"])},
  "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Passwörter stimmen nicht überein."])},
  "passwordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort erfolgreich aktualisiert."])},
  "passwordUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Aktualisieren des Passworts."])},
  "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails"])},
  "editPaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails bearbeiten"])},
  "updatePaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails aktualisieren"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
  "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "latvian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettisch"])},
  "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russisch"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "allActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Aktivitäten"])},
  "roleManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollenverwaltung"])},
  "userManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerverwaltung"])},
  "locationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standortverwaltung"])},
  "cohortManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohortenverwaltung"])},
  "calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalenderansicht"])},
  "whatsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was gibt's Neues"])},
  "extracurricularActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Außerschulische Aktivitäten"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
  "navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
  "filterActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitäten filtern"])},
  "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort auswählen"])},
  "selectTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehrer auswählen"])},
  "selectCohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohorte auswählen"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwenden"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "goToDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu den Details"])},
  "monthView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatsansicht"])},
  "weekView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochenansicht"])},
  "dayView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagesansicht"])},
  "activityApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchungsbestätigungszentrum"])},
  "holidayManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferienverwaltung"])},
  "noPendingBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurzeit keine ausstehenden Buchungen."])},
  "confirmBookingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung bestätigen"])},
  "confirmBookingMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie ", _interpolate(_named("activityName")), " für ", _interpolate(_named("childName")), " buchen?"])},
  "confirmBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung bestätigen"])},
  "doYouWantToBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie buchen"])},
  "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["für"])},
  "paymentDetailsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wir werden Sie bezüglich der Zahlungsdetails kontaktieren. Der Preis für die Aktivität beträgt ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), ". Sobald die Zahlung erfolgt ist, können wir die Buchung bestätigen."])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
  "currentActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Aktivitäten"])},
  "upcomingActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevorstehende Aktivitäten"])},
  "filterByChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Kind filtern"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal."])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verarbeitung..."])},
  "activityBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität erfolgreich gebucht, wartet auf Bestätigung."])},
  "confirmCancellationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie die Buchung für ", _interpolate(_named("activityName")), " für ", _interpolate(_named("childName")), " stornieren?"])},
  "confirmCancellationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornierung bestätigen"])},
  "confirmAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion bestätigen"])},
  "confirmActionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diese Aktion ausführen möchten?"])},
  "bookingCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung wurde storniert."])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum"])},
  "timeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitfenster"])},
  "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer"])},
  "cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohorten"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeilen pro Seite"])}
}