export default {
  "findActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдите нужное занятие"])},
  "bookActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забронируйте из множества занятий."])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать в ваш портал бронирования"])},
  "userNameEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя пользователя или Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
  "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильное имя пользователя или пароль"])},
  "createAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать администратора"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя пользователя"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
  "settingsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
  "selectLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите язык"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить пароль"])},
  "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий пароль"])},
  "passwordTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот пароль будет использоваться пользователем для входа в систему. Убедитесь, что он надежен. Администраторы могут устанавливать и изменять пароли, но пользователи могут изменять их после входа в систему. Администраторы не могут просматривать пароли пользователей."])},
  "subRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль пользователя"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль"])},
  "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердите новый пароль"])},
  "reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напоминания"])},
  "selectTimeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите временные слоты"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно"])},
  "remindersManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление напоминаниями"])},
  "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить пароль"])},
  "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новые пароли не совпадают."])},
  "publishUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликовать пользователя"])},
  "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить пользователя"])},
  "saveDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить черновик"])},
  "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения"])},
  "passwordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль успешно обновлен."])},
  "passwordUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось обновить пароль."])},
  "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежные данные"])},
  "editPaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать платежные данные"])},
  "updatePaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить платежные данные"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Английский"])},
  "noSlotsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет доступных слотов"])},
  "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немецкий"])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напоминание"])},
  "latvian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Латышский"])},
  "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
  "roleManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ролями"])},
  "allActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все занятия"])},
  "userManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление пользователями"])},
  "locationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление местоположением"])},
  "cohortManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление когортой"])},
  "calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарный вид"])},
  "whatsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что нового"])},
  "extracurricularActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внеклассные мероприятия"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
  "navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Навигация"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
  "filterActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтровать занятия"])},
  "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать местоположение"])},
  "selectTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать учителя"])},
  "selectCohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать когорту"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
  "goToDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к деталям"])},
  "monthView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месячный вид"])},
  "weekView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недельный вид"])},
  "dayView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дневной вид"])},
  "activityApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центр утверждения бронирования"])},
  "holidayManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление праздниками"])},
  "noPendingBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В данный момент нет ожидающих бронирований."])},
  "confirmBookingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить бронирование"])},
  "confirmBookingMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы хотите забронировать ", _interpolate(_named("activityName")), " для ", _interpolate(_named("childName")), "?"])},
  "confirmBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить бронирование"])},
  "doYouWantToBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы хотите забронировать"])},
  "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["для"])},
  "paymentDetailsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Мы свяжемся с вами по поводу деталей оплаты. Стоимость занятия ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), ". После оплаты мы сможем подтвердить бронирование."])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
  "currentActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущие занятия"])},
  "upcomingActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предстоящие занятия"])},
  "filterByChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр по ребенку"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что-то пошло не так. Пожалуйста, попробуйте позже."])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработка..."])},
  "activityBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Занятие успешно забронировано, ожидает подтверждения."])},
  "confirmCancellationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы хотите отменить бронирование для ", _interpolate(_named("activityName")), " для ", _interpolate(_named("childName")), "?"])},
  "confirmCancellationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить отмену"])},
  "confirmAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить действие"])},
  "confirmActionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите продолжить это действие?"])},
  "bookingCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование отменено."])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания"])},
  "timeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Временные интервалы"])},
  "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники"])},
  "cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когорты"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Строк на странице"])}
}