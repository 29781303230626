import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/notify/Notify.js';import Dialog from 'quasar/src/plugins/dialog/Dialog.js';;
import '@quasar/extras/material-icons/material-icons.css';
import 'quasar/dist/quasar.css';
import './styles/quasar.scss';
import quasarUserOptions from './quasar-user-options';
import { createI18n } from 'vue-i18n';
import store from './store';
import eventBus from './eventBus';
import UnauthorizedError from '@/components/UnauthorizedError.vue'; 
import axios from 'axios'; 

import en from '@/locales/en.json';
import de from '@/locales/de.json';
import lv from '@/locales/lv.json';
import ru from '@/locales/ru.json';
import VueGoogleMaps from '@fawmi/vue-google-maps';

import QCalendar from '@quasar/quasar-ui-qcalendar';
import '@quasar/quasar-ui-qcalendar/dist/index.css';

import { io } from 'socket.io-client';

const messages = {
  en,
  de,
  lv,
  ru
};

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages
});

const debounce = (callback, delay) => {
  let tid;
  return function (...args) {
    const ctx = this;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _ = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ {
  constructor(callback) {
    callback = debounce(callback, 20);
    super(callback);
  }
};

const app = createApp(App);
app.use(store);
app.use(router);
app.use(i18n);
app.use(Quasar, { plugins: { Notify, Dialog }, ...quasarUserOptions });
app.use(VueGoogleMaps, {
  load: {
    key: 'YOUR_GOOGLE_MAPS_API_KEY',
    libraries: 'places'
  }
});
app.use(QCalendar);

app.component('UnauthorizedError', UnauthorizedError);

app.config.globalProperties.$eventBus = eventBus;

const socket = io(process.env.VUE_APP_SOCKET_URL || 'https://localhost:5005');
app.config.globalProperties.$socket = socket;

app.mount('#app');

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.data && error.response.data.message === "Access denied. You do not have the required permissions.") {
      Notify.create({
        type: 'negative',
        message: 'You do not have the permission to do this. Please contact the administrator.'
      });
    }
    return Promise.reject(error);
  }
);

// Add the log statement here to check the API base URL
console.log("API Base URL:", process.env.VUE_APP_API_BASE_URL);
