export default {
  "findActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find the right activity"])},
  "bookActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book from a wide range of activities."])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to your booking portal!"])},
  "userNameEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name or Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid username or password"])},
  "createAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Admin"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminders"])},
  "remindersManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminders Management"])},
  "settingsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "reminderManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminder Management"])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminders"])},
  "selectLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Language"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
  "passwordTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This password will be used by the user to log in. Ensure it is secure. Admins can set and change passwords, but users can change them after logging in. Admins cannot view user passwords."])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password"])},
  "noSlotsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No slots available"])},
  "selectTimeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select time slots"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
  "subRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Role"])},
  "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User"])},
  "saveDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Draft"])},
  "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
  "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Details"])},
  "publishUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish User"])},
  "editPaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Payment Details"])},
  "updatePaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Payment Details"])},
  "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Password"])},
  "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New passwords do not match."])},
  "passwordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password updated successfully."])},
  "passwordUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to update password."])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
  "latvian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvian"])},
  "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "allActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Activities"])},
  "userManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Management"])},
  "locationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location Management"])},
  "cohortManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohort Management"])},
  "calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar View"])},
  "whatsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's New"])},
  "extracurricularActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extracurricular Activities"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "filterActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Activities"])},
  "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Location"])},
  "selectTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Teacher"])},
  "selectCohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Cohort"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "goToDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Details"])},
  "monthView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month View"])},
  "weekView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week View"])},
  "dayView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day View"])},
  "activityApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Approval Center"])},
  "holidayManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holiday Management"])},
  "noPendingBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pending bookings at the moment."])},
  "confirmBookingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Booking"])},
  "confirmBookingMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to book ", _interpolate(_named("activityName")), " for ", _interpolate(_named("childName")), "?"])},
  "confirmBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Booking"])},
  "doYouWantToBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to book"])},
  "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for"])},
  "paymentDetailsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We will contact you with the payment details. The price for the activity is ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), ". Once the payment is made, we can confirm the booking."])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "currentActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Activities"])},
  "upcomingActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming Activities"])},
  "filterByChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by Child"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Please try again later."])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing..."])},
  "activityBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity booked successfully, waiting for approval."])},
  "confirmCancellationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to cancel the booking for ", _interpolate(_named("activityName")), " for ", _interpolate(_named("childName")), "?"])},
  "confirmCancellationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Cancellation"])},
  "confirmAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Action"])},
  "confirmActionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to proceed with this action?"])},
  "bookingCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking has been canceled."])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
  "timeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Slots"])},
  "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
  "cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohorts"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rows per page"])},
  "roleManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role Management"])}
}