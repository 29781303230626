// src/quasar-user-options.js
import '@quasar/extras/material-icons/material-icons.css';
import 'quasar/dist/quasar.css';
import Notify from 'quasar/src/plugins/notify/Notify.js';;

export default {
  config: {},
  plugins: {
    Notify
  },
};
